import React, { useState } from "react"
import update from "immutability-helper"
import { ButtonGroup, Button } from "@shopify/polaris"
import { useTour } from "@reactour/tour"

function Template(props) {
  const { state, setState, setProductEdit, setCustomizationEdit } = props
  const [template, setTemplate] = useState(null)
  const {
    isOpen,
    currentStep,
    steps,
    setIsOpen,
    setCurrentStep,
    setSteps,
  } = useTour()
  const handleSubscriptionTemplate = () => {
    setTemplate("Subscription")
    const productSize = state.product.length
    if(productSize === 0){
      setState(
        update(state, {
          product: { $push: [{}] },
          banner: { $set: "Subscribe and Save" },
          swap: { $set: true },
        })
      )
    }
    setProductEdit(0)
    setCustomizationEdit(true)
    setTimeout(function () {
      setSteps([
        {
          selector: ".first-step",
          content:
            "Choose a product with a selling plan attached. Select ‘Add as a subscription’. Choose a selling plan.",
          position: "right",
        },
        {
          selector: ".second-step .Polaris-TextField",
          content:
            "Set the callout banner to something like ‘Subscribe and Save’ to inform customers",
          position: "right",
        },
        {
          selector: ".third-step",
          content:
            "Select the ‘Swap’ option. Choose the product for which this offer should be shown",
          position: "right",
          highlightedSelectors: ['.swap-quantity'],
          mutationObservables: ['.swap-quantity']
        },
        { selector: ".fourth-step", content: "Save!", position: "right" },
      ])
      setCurrentStep(0)
      setIsOpen(true)
    }, 1000)
  }
  const handleSingleProductTemplate = () => {
    setTemplate("Single product")
    const productSize = state.product.length
    if(productSize === 0){
      setState(update(state, { product: { $push: [{}] } }))
    }
    setProductEdit(0)
    setCustomizationEdit(true)
    setTimeout(function () {
      setSteps([
        {
          selector: ".first-step",
          content:
            "Choose a product you want to upsell and set a clear product title and subtitle",
          position: "right",
        },
        {
          selector: ".second-step .Polaris-TextField",
          content: "Intro your recommendation with a callout banner",
          position: "right",
        },
        { selector: ".fourth-step", content: "Save!", position: "right" },
      ])
      setCurrentStep(0)
      setIsOpen(true)
    }, 1000)
  }
  const handleGiftwithPurchaseTemplate = () => {
    setTemplate("Gift with purchase")
    const productSize = state.product.length
    if(productSize === 0){
      setState(
        update(state, {
          product: { $push: [{ compareAtPrice: { value: "0", sym: "%" } }] },
          autoAdd: { $set: true },
        })
      )
    }
    setProductEdit(0)
    setCustomizationEdit(true)
    setTimeout(function () {
      setSteps([
        {
          selector: ".first-step",
          content: "Choose the item you want to offer as a free gift",
          position: "right",
        },
        {
          selector: ".discount-step",
          content: "Set the price to $0",
          position: "right",
        },
        {
          selector: ".smart-rule-step",
          content:
            "Set the conditions for when the free gift should be added to cart",
          position: "right",
        },
        {
          selector: ".add-to-cart-auto-step",
          content:
            "Check ‘Automatically add to cart when conditions are met’ to make the gift auto add to cart",
          position: "right",
        },
        { selector: ".fourth-step", content: "Save!", position: "right" },
      ])
      setCurrentStep(0)
      setIsOpen(true)
    }, 1000)
  }
  return (
    <div style={{ margin: "5px 0 15px 25px" }}>
      <p style={{ margin: "10px 0" }}>Template</p>
      <ButtonGroup spacing="extraTight">
        <Button
          size="slim"
          onClick={() => handleSingleProductTemplate()}
          pressed={"Single product" === template ? true : false}
        >
          Single product
        </Button>
        <Button
          size="slim"
          onClick={() => handleSubscriptionTemplate()}
          pressed={"Subscription" === template ? true : false}
        >
          Subscription
        </Button>
        <Button
          size="slim"
          onClick={() => handleGiftwithPurchaseTemplate()}
          pressed={"Gift with purchase" === template ? true : false}
        >
          Gift with purchase
        </Button>
      </ButtonGroup>
    </div>
  )
}

export default Template
