import { axiosInterceptor } from './axiosInterceptor'

export default (token, shop, host, payload) => {
  const axiosWithToken = axiosInterceptor(shop, host)
  return axiosWithToken.request({
    url: `/createAutomaticDiscount`,
    method: 'post',
    data: payload,
    headers: {
      'X-Shopify-Access-Token': token,
      'X-Shopify-Shop-Domain': shop,
    },
  })
}